import React from 'react';
import * as R from 'ramda';
import { Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

const ContentAccessButton = (props) => {
  const { record, source } = props;
  const courseId = R.pathOr('', [source, 'openedx'], record);

  if (!courseId) return null;

  const isGitHubCourse = courseId.startsWith('github:');

  let url;
  if (isGitHubCourse) {
    // expected format: github:org:type:repoOwner:repoName:repoBranch
    const parts = courseId.split(':');
    if (parts.length < 5) return null;

    const repoOwner = parts[2];
    const repoName = parts[3];
    const repoBranch = parts[4];
    url = `https://github.com/${repoOwner}/${repoName}/tree/${repoBranch}`;
  } else {
    url = `${process.env.REACT_APP_STUDIO_BASE_URL}/course/${courseId}`;
  }

  return (
    <Button
      size="small"
      color="primary"
      target="_blank"
      variant="outlined"
      href={url}
    >
      <OpenInNew style={{ paddingRight: '0.2em' }} />
      {isGitHubCourse ? 'GITHUB' : 'STUDIO'}
    </Button>
  );
};

export default ContentAccessButton;
