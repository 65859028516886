import React from 'react';
import * as R from 'ramda';
import { useQuery } from 'react-admin';

import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  RichTextField,
  TextField,
  Toolbar,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';

import { FlagField } from 'resources/one_on_ones/fields';

const EditOneOnOneButton = ({ record, student }) => {
  const { data: cohort } = useQuery({
    type: 'getOne',
    resource: student?.is_b2b ? 'cohorts_b2b' : 'cohorts',
    payload: { id: student?.cohort },
  });

  return (
    <Button
      component={Link}
      variant="outlined"
      to={{
        pathname: `/one_on_ones/create`,
        state: {
          student,
          module: record.module,
          isWeekly: !record.module,
          redirect: `/students/${student.id}/show/one_on_ones`,
          cohort: cohort,
        },
      }}
      label="Edit"
    >
      <Edit />
    </Button>
  );
};

const OneOnOneComments = (props) => {
  return <RichTextField source="comments" {...props} />;
};

function OneOnOnesTab({ basePath, record, ...rest }) {
  const { data: cohort } = useQuery({
    type: 'getOne',
    resource: record?.is_b2b ? 'cohorts_b2b' : 'cohorts',
    payload: { id: record?.cohort },
  });

  const showCreateButton = R.pipe(
    R.propOr([], 'one_on_ones'),
    R.length,
    R.equals(3),
    R.not
  )(record);
  return (
    <Box pt="16px">
      <ArrayField source="one_on_ones" record={record}>
        <Datagrid
          rowClick="expand"
          expand={<OneOnOneComments record={record} {...rest} />}
          selectedIds={[]}
        >
          <TextField label="Module" source="module" sortable={false} />
          <TextField
            label="Interviewer"
            source="interviewer"
            sortable={false}
          />
          <DateField label="Date" source="date" sortable={false} />
          <FlagField label="Flagged" />
          <EditOneOnOneButton student={record} />
        </Datagrid>
      </ArrayField>
      <Toolbar>
        {showCreateButton && (
          <Button
            component={Link}
            variant="outlined"
            to={{
              pathname: '/one_on_ones/create',
              state: {
                student: record,
                isWeekly: true,
                redirect: `/students/${record.id}/show/one_on_ones`,
                cohort: cohort,
              },
            }}
            label="Create One On One"
          >
            <Add />
          </Button>
        )}
      </Toolbar>
    </Box>
  );
}

export default OneOnOnesTab;
