import React from 'react';
import * as R from 'ramda';
import { Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

const PreviewAccessButton = (props) => {
  const { record, source } = props;
  const courseId = R.pathOr('', [source, 'openedx'], record);

  if (!courseId) return null;

  const url = `${process.env.REACT_APP_PREVIEW_BASE_URL}/lms/courses/${courseId}`;
  return (
    <Button
      size="small"
      color="primary"
      target="_blank"
      variant="outlined"
      href={url}
    >
      <OpenInNew style={{ paddingRight: '0.2em' }} />
      Preview
    </Button>
  );
};

export default PreviewAccessButton;
